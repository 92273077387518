import { FC, useMemo } from "react";
import { IOrderPartProduct } from "../types";
import { useTranslation } from "react-i18next";
import { LMTable } from "../../../components/LMTable";
import { ColumnType } from "antd/es/table";
import { extendByMetadata } from "../utils";
import styles from "./OrderPartProducts.module.css";

interface Props {
  products: IOrderPartProduct[];
}

export const OrderPartProducts: FC<Props> = ({ products }) => {
  const { t } = useTranslation("order");

  const columns = useMemo(
    () => [
      {
        title: t("packId"),
        dataIndex: "packId",
        key: "packId",
        render: (packId: string, product: IOrderPartProduct, index: number) => {
          const packProducts = products.filter((p) => p.packId === packId);
          const count = packProducts.length;
          const firstProduct = packProducts[0];
          const firstProductIndex = products.indexOf(firstProduct);
          if (count > 1 && index === firstProductIndex) {
            return {
              props: {
                rowSpan: count,
                className: styles.merged,
              },
              children: packId || "—",
            };
          }
          if (count > 1) {
            return {
              props: {
                rowSpan: 0,
              },
            };
          }
          return packId || "—";
        },
      },
      {
        title: t("productName"),
        dataIndex: "name",
        key: "name",
      },
      {
        title: t("sku"),
        key: "sku",
        dataIndex: "sku",
      },
      ...extendByMetadata(products).metadataList.map((metadataName) => ({
        title: metadataName.title,
        key: metadataName.value,
        dataIndex: metadataName.value,
      })),
    ],
    [t, products]
  ) as ColumnType<IOrderPartProduct>[];

  return (
    <div className={styles.container}>
      <LMTable
        bordered
        columns={columns}
        dataSource={extendByMetadata(products).productsWithMetadata}
      />
    </div>
  );
};
