import { AxiosPromise } from 'axios';
import { axios } from '../axios';
import { ActivityTypes } from '../systems';
import { ISystemActivities, ISystemsState } from './types';

export const getState  = (systemId: number): AxiosPromise<ISystemsState> => {
  return axios.get(`/systems/${systemId}/state`);
}

export const updateHealth = (systemId: number, status: boolean) => {
  return axios.patch(`/systems/${systemId}/health?healthy=${status}`);
}

export const getActivity  = (tenantId: number, startTime: Date, endTime: Date, type: ActivityTypes[]): AxiosPromise<ISystemActivities> => {
  return axios.get(`/orders/activity?tenantId=${tenantId}&startTime=${startTime.toISOString()}&endTime=${endTime.toISOString()}&types=${type.join(",")}`);
}

export const updateEStop = (systemId: number, status: boolean) => {
  return axios.patch(`/systems/${systemId}/estop?active=${status}`);
};