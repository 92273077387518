import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LMNewSwitch } from "../../../components/LMNewSwitch";
import { Button2, H3 } from "../../../components/Typography";
import { ISystemTasksValues } from "../types";
import styles from "./SystemTasks.module.css";

interface Props {
  values: ISystemTasksValues;
  onChange: (values: ISystemTasksValues) => void;
}

export const SystemTasksModalContent: FC<Props> = ({ values, onChange }) => {
  const { t } = useTranslation("systemTasks");
  return (
    <div className={styles["confirmation-container"]}>
      <div className={styles["confirmation-title"]}>
        <H3>{t("title")}</H3>
      </div>
      <div className={styles["switch-group"]}>
        <div className={styles["switch-line"]}>
          <Button2>{t("orders")}</Button2>
          <LMNewSwitch
            defaultChecked={values.Orders}
            onChange={() => onChange({ ...values, Orders: !values.Orders })}
          />
        </div>
        <div className={styles["switch-line"]}>
          <Button2>{t("maintenance")}</Button2>
          <LMNewSwitch
            defaultChecked={values.Maintenance}
            onChange={() =>
              onChange({ ...values, Maintenance: !values.Maintenance })
            }
          />
        </div>
        <div className={styles["switch-line"]}>
          <Button2>{t("deliveries")}</Button2>
          <LMNewSwitch
            defaultChecked={values.Deliveries}
            onChange={() =>
              onChange({ ...values, Deliveries: !values.Deliveries })
            }
          />
        </div>
      </div>
    </div>
  );
};
