import {FC, useEffect} from "react";
import {Drawer} from "antd";
import {useSearchParams} from "react-router-dom";
import {IconArrowLeft} from "../../../assets";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {RootState} from "../../../app/store";
import {actions} from "../slice";
import {CreateIndexingRequestForm} from "../CreateIndexingRequestForm";
import styles from "./CreateIndexingRequestDrawer.module.css";
import {IStation} from "../../system";
import {EPalletType} from "../../system/types";

interface Props {
  systemId: number;
}

export const CreateIndexingRequestDrawer: FC<Props> = ({ systemId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { t } = useTranslation("indexing");

  const { isCreateDrawerOpen, maintenances } = useSelector(
    (state: RootState) => {
      return state.indexing;
    }
  );

  const stations = useSelector((state: RootState) => {
    return state.system.stations
  });

  useEffect(() => {
    if (searchParams.get("create")) {
      dispatch(actions.setIsCreateDrawerOpen(true));
    }
  }, [searchParams, dispatch]);

  const handleResetSearchParams = (visible: boolean) => {
    if (!visible) setSearchParams({});
  };

  const onCloseHandler = () => {
    dispatch(actions.setIsCreateDrawerOpen(false));
  };

  return (
    <div className={styles.container}>
      <Drawer
        open={isCreateDrawerOpen}
        width={800}
        afterOpenChange={handleResetSearchParams}
        closable={false}
        className={styles.drawer}
        onClose={onCloseHandler}
      >
        <header className={styles.header}>
          <div className={styles["close-icon"]} onClick={onCloseHandler}>
            <IconArrowLeft />
          </div>
          <span className={styles.title}>{t("createTitle")}</span>
        </header>
        <CreateIndexingRequestForm
          systemId={systemId}
          isDrawerOpen={isCreateDrawerOpen}
          maintenances={maintenances}
          stations={stations}
        />
      </Drawer>
    </div>
  );
};
