import { FC } from "react";
import { Collapse } from "antd";
import { IOrderPart } from "../types";
import { OrderPartProducts } from "../OrderPartProducts";
import { OrderPartItem } from "./OrderPartItem";
import { sortOrderPartProducts } from "../utils";
import styles from "./OrderParts.module.css";

const { Panel } = Collapse;

interface Props {
  parts: IOrderPart[];
}

export const OrderParts: FC<Props> = ({ parts }) => {
  console.log(parts);
  return (
    <Collapse className={styles.container}>
      {parts.map((part) => (
        <Panel header={<OrderPartItem part={part} />} key={part.id}>
          <OrderPartProducts
            products={part.products.slice().sort(sortOrderPartProducts)}
          />
        </Panel>
      ))}
    </Collapse>
  );
};
