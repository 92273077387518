import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EOrderStatus,
  EOrdersViewType,
  IFetchListProps,
  IOrder,
  IOrdersSliceState,
  IShippingDetailsEvent,
} from "./types";
import { ApiStatuses, IDateRange, SortTypes } from "../../app/types";
import {
  getOrder,
  getOrders,
  getShipmentOperations,
  getBoard,
} from "./api";
import { MessageOperation } from "../signalR";

export const initialState: IOrdersSliceState = {
  list: [],
  board: [],
  filters: {
    page: 1,
    idFilter: "",
    statusFilter: [],
    dateFilter: { start: null, end: null },
    dateFilterSort: "none",
    pageSize: 10,
    total: 0,
    viewType: null,
  },
  status: ApiStatuses.initial,
  detailsStatus: ApiStatuses.initial,
  orderNotFound: false,
};

export const fetchList = createAsyncThunk(
  "orders/fetchList",
  async ({
    tenantId,
    filters
  }: IFetchListProps) => {
    const response = await getOrders(
      tenantId,
      filters
    );
    return response.data;
  }
);

export const fetchBoard = createAsyncThunk(
  "orders/fetchBoard",
  async ({ tenantId, idFilter }: { tenantId: number; idFilter: string }) => {
    const response = await getBoard(tenantId, idFilter);
    return response.data;
  }
);

export const fetchOrder = createAsyncThunk(
  "orders/fetchOrder",
  async ({ orderId }: { orderId: string }) => {
    const response = await getOrder(orderId);
    return response.data;
  }
);

export const refreshOrder = createAsyncThunk(
  "orders/refreshOrder",
  async ({ orderId }: { orderId: string }) => {
    const response = await getOrder(orderId);
    return response.data;
  }
);


export const updateShipping = createAsyncThunk(
  "orders/updateShipping",
  async ({
    operation,
    shippingString,
  }: {
    operation: MessageOperation;
    shippingString: string;
  }) => {
    if (operation === MessageOperation.Updates) {
      const shippingDetails = JSON.parse(
        shippingString
      ) as IShippingDetailsEvent;
      const responseOperations = await getShipmentOperations(
        shippingDetails.orderId
      );
      const responseOrder = await getOrder(shippingDetails.orderId);
      return {
        operations: responseOperations.data,
        order: responseOrder.data,
        shippingDetails,
      };
    }
  }
);

const updateShippingStatusInTheOrderList = (
  orders: IOrder[],
  shippingDetails: IShippingDetailsEvent
) => {
  const order = orders.find((o) => o.id === shippingDetails.orderId);
  if (!order || !order.shippings) return orders;
  const shippings = order.shippings.map((shipping) => {
    if (shipping.id === shippingDetails.id) {
      return {
        ...shipping,
        status: shippingDetails.status,
      };
    }
    return shipping;
  });
  return orders.map((order) => {
    if (order.id === shippingDetails.orderId) {
      return {
        ...order,
        shippings,
      };
    }
    return order;
  });
};

const slice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setSelectedOrderId(
      state: IOrdersSliceState,
      action: PayloadAction<number | undefined>
    ) {
      if (!action.payload) {
        state.orderNotFound = false;
      }
      state.selectedOrderId = action.payload;
    },
    setPage(state: IOrdersSliceState, action: PayloadAction<number>) {
      state.filters.page = action.payload;
    },
    setPageSize(state: IOrdersSliceState, action: PayloadAction<number>) {
      state.filters.pageSize = action.payload;
    },
    setFilters(
      state: IOrdersSliceState,
      action: PayloadAction<{
        id: string;
        statuses: string;
        viewType: string;
        dates: IDateRange;
      }>
    ) {
      state.filters.idFilter = action.payload.id;
      state.filters.statusFilter = action.payload.statuses
        ? (action.payload.statuses.split(",") as EOrderStatus[])
        : [];
      state.filters.viewType = action.payload.viewType as EOrdersViewType;
      state.filters.dateFilter = action.payload.dates;
      state.filters.page = 1;
    },
    setDateSort(state: IOrdersSliceState, action: PayloadAction<SortTypes>) {
      state.filters.dateFilterSort = action.payload;
    },
    clearFilters(state: IOrdersSliceState) {
      state.filters.statusFilter = [];
      state.filters.idFilter = "";
      state.filters.dateFilter = { start: null, end: null };
    },
    updateList(
      state: IOrdersSliceState,
      action: PayloadAction<{
        operation: MessageOperation;
        orderString: string;
      }>
    ) {
      if (state.status !== ApiStatuses.success) return;
      const order = JSON.parse(action.payload.orderString) as IOrder;
      if (state.selectedOrder && state.selectedOrder.id === order.id) {
        state.selectedOrder = order;
      }
      const viewType =
        state.filters.viewType === EOrdersViewType.board ? "board" : "list";
      if (action.payload.operation === MessageOperation.Updates) {
        state[viewType] = state[viewType].map((o) =>
          o.id === order.id ? order : o
        );
      }
      if (
        action.payload.operation === MessageOperation.Create &&
        state.filters.page === 1
      ) {
        state[viewType] = [order, ...state[viewType].slice(0, -1)];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchList
      .addCase(fetchList.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.list = action.payload.values;
        state.filters.total = action.payload.count;
      })
      .addCase(fetchList.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchBoard
      .addCase(fetchBoard.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchBoard.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.board = action.payload;
      })
      .addCase(fetchBoard.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchOrder
      .addCase(fetchOrder.pending, (state) => {
        state.orderNotFound = false;
        state.detailsStatus = ApiStatuses.loading;
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.detailsStatus = ApiStatuses.success;
        state.selectedOrder = action.payload;
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        state.detailsStatus = ApiStatuses.fail;
        state.orderNotFound = !!action.error.message?.includes("404");
      })
      // updateShipping
      .addCase(updateShipping.fulfilled, (state, action) => {
        if (!action.payload) return;
        const shipping = action.payload.shippingDetails;
        const order = action.payload.order;
        state.list = updateShippingStatusInTheOrderList(state.list, shipping);
        if (state.selectedOrder?.id === shipping.orderId) {
          state.selectedOrder = order;
        }
      })
      // refreshOrder
      .addCase(refreshOrder.fulfilled, (state, action) => {
        state.selectedOrder = action.payload;
      })
  },
});

export const orders = slice.reducer;
export const actions = slice.actions;
