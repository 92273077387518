import { splitCamelCase } from "../../app/utils";
import { EOrderStatus, IOrder, IOrderPartProduct, IOrderPartProductWithMetadata } from "./types";

export const getColorByStatus = (status?: EOrderStatus) => {
  if (!status) {
    return "gray"
  }
  if (status === EOrderStatus.Canceled) {
    return "yellow";
  }
  if (status === EOrderStatus.Failed || status === EOrderStatus.DeliveryFailed ||
    status === EOrderStatus.PackingFailed || status === EOrderStatus.ProcessingFailed) {
    return "red";
  }
  if (status === EOrderStatus.Collected || status === EOrderStatus.CollectedManually ||
    status === EOrderStatus.Shipped) {
    return "green";
  }
  return "violet";
}

export const getOrderPartId = (order: IOrder) => {
  if (!order.parts) return undefined;
  const part = order.parts[order.parts.length - 1];
  return part ? part.id : undefined;
}

export const extendByMetadata = (products: IOrderPartProduct[]) => {
  const metadataList: { title: string, value: string }[] = [];
  const productsWithMetadata: IOrderPartProductWithMetadata[] = [];
  products.forEach(product => {
    if (!product.itemsMetadata) {
      productsWithMetadata.push(product as IOrderPartProductWithMetadata);
      return;
    }
    let currentProductMetadata = {};
    product.itemsMetadata.forEach(metadata => {
      currentProductMetadata = {
        ...currentProductMetadata,
        ...metadata
      }
      Object.keys(metadata).forEach(metadataKey => {
        if (!metadata[metadataKey] || metadataList.some(metadataItem => metadataItem.value === metadataKey)) return;
        metadataList.push({ title: splitCamelCase(metadataKey), value: metadataKey });
      })
    });
    const productWithMetadata = {
      ...product,
      ...currentProductMetadata
    } as IOrderPartProductWithMetadata;
    productsWithMetadata.push(productWithMetadata);
  })
  return {
    metadataList,
    productsWithMetadata
  }
}

export const sortOrderPartProducts = (a: IOrderPartProduct, b: IOrderPartProduct) => {
  const aPackId = a.packId || "";
  const bPackId = b.packId || "";
  if (aPackId < bPackId) return 1;
  if (aPackId > bPackId) return -1;
  return 0;
};