import { FC, useCallback, useEffect, useMemo } from "react";
import { ColumnType } from "antd/es/table";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { SystemStationDetailsHeader } from "../SystemStationDetails/SystemStationDetailsHeader";
import { AppDispatch, RootState } from "../../../app/store";
import { fetchStationOrders } from "../slice";
import { DATE_FORMAT, TIME_NO_SECONDS_FORMAT } from "../../../app/constants";
import { OrderStatus, EOrderStatus, IOrder } from "../../orders";
import styles from "./SystemStationOrders.module.css";
import { LMTable } from "../../../components/LMTable";
import { ApiStatuses } from "../../../app/types";
import { EPalletType } from "../types";

interface Props {
  onHide: () => void;
  systemId: number;
  tenantId: number;
}

export const SystemStationOrders: FC<Props> = ({
  onHide,
  systemId,
  tenantId,
}) => {
  const { t } = useTranslation("orders");
  const dispatch = useDispatch<AppDispatch>();
  const { selectedStation, stationOrdersStatus, stationOrders } = useSelector(
    (state: RootState) => {
      return state.system;
    }
  );

  const columns = useMemo(
    () => [
      {
        title: t("id"),
        dataIndex: "id",
        key: "id",
      },
      {
        title: t("time"),
        key: "created",
        dataIndex: "createdOn",
        render: (createdOn: string) => {
          return (
            <>
              {format(new Date(createdOn), DATE_FORMAT)}
              <span className={styles.time}>
                &nbsp;
                {format(new Date(createdOn), TIME_NO_SECONDS_FORMAT)}
              </span>
            </>
          );
        },
        sorter: true,
      },
      {
        title: t("status"),
        dataIndex: "status",
        key: "status",
        render: (status: EOrderStatus) => <OrderStatus status={status} />,
      },
    ],
    [t]
  ) as ColumnType<IOrder>[];

  const fetchOrders = useCallback(() => {
    if (!selectedStation || !selectedStation.ordersIds) return;
    dispatch(
      fetchStationOrders({ tenantId, orderIds: selectedStation.ordersIds })
    );
  }, [selectedStation, dispatch, tenantId]);

  useEffect(() => {
    if (!selectedStation) return;
    fetchOrders();
  }, [fetchOrders, selectedStation, selectedStation?.ordersIds]);

  const handleRowClick = (order: IOrder) =>
    window.open(`/${systemId}/orders/${order.id}`, "_blank");

  const onRow = (order: IOrder) => ({
    onClick: () => handleRowClick(order),
    style: { cursor: "pointer" },
  });

  return (
    <>
      <Drawer
        title={
          selectedStation ? (
            <SystemStationDetailsHeader station={selectedStation} />
          ) : (
            <></>
          )
        }
        width="50%"
        placement="right"
        onClose={onHide}
        open={
          !!(selectedStation && selectedStation.type === EPalletType.BufferHive)
        }
      >
        <LMTable
          loading={stationOrdersStatus === ApiStatuses.loading}
          columns={columns}
          dataSource={stationOrders}
          total={{ title: t("total"), amount: stationOrders.length }}
          onRow={onRow}
        />
      </Drawer>
    </>
  );
};
