import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Body2, H3, Label } from "../../../components/Typography";
import { ISystemsState } from "../../systemAttributes";
import { IStation } from "../types";
import styles from "./SystemStations.module.css";
import {
  IconSystemBuffers,
  IconSystemCompressor,
  IconSystemPackerStock,
  IconSystemStations,
  IconSystemTemperature,
} from "../../../assets";
import { ApiStatuses, Nullable } from "../../../app/types";
import { TFunction } from "i18next";

const getCompressorState = (t: TFunction, compressor: Nullable<boolean>) => {
  if (compressor === undefined) {
    return "-";
  }
  return compressor ? t("on") : t("off");
};

interface Props {
  state: ISystemsState;
  stationsStatus: ApiStatuses;
  stations: IStation[];
}

export const SystemStationsHeader: FC<Props> = ({
  state,
  stations,
  stationsStatus,
}) => {
  const { t } = useTranslation("system");
  return (
    <div className={styles.header}>
      {stationsStatus === ApiStatuses.success && (
        <div className={styles["header-item-container"]}>
          <div className={styles["header-icon-container"]}>
            <IconSystemStations />
          </div>
          <div className={styles["header-item-content"]}>
            <div className={styles["header-item-content-value"]}>
              <H3>{stations.filter((s) => s.palletId).length}</H3>
              <Body2 className={styles.light}>/{stations.length}</Body2>
            </div>
            <Label className={styles["header-item-content-label"]}>
              {t("stations")}
            </Label>
          </div>
        </div>
      )}
      <div className={styles["header-item-container"]}>
        <div className={styles["header-icon-container"]}>
          <IconSystemCompressor />
        </div>
        <div className={styles["header-item-content"]}>
          <div className={styles["header-item-content-value"]}>
            <H3>{getCompressorState(t, state.compressor)}</H3>
          </div>
          <Label className={styles["header-item-content-label"]}>
            {t("compressor")}
          </Label>
        </div>
      </div>
      {state.buffer && (
        <div className={styles["header-item-container"]}>
          <div className={styles["header-icon-container"]}>
            <IconSystemBuffers />
          </div>
          <div className={styles["header-item-content"]}>
            <div className={styles["header-item-content-value"]}>
              <H3>{state.buffer.occupied}</H3>
              <Body2 className={styles.light}>/{state.buffer.total}</Body2>
            </div>
            <Label className={styles["header-item-content-label"]}>
              {t("buffers")}
            </Label>
          </div>
        </div>
      )}
      <div className={styles["header-item-container"]}>
        <div className={styles["header-icon-container"]}>
          <IconSystemPackerStock />
        </div>
        <div className={styles["header-item-content"]}>
          <div className={styles["header-item-content-value"]}>
            <H3>{state.packerSupplyPercentage}%</H3>
          </div>
          <Label className={styles["header-item-content-label"]}>
            {t("packerStock")}
          </Label>
        </div>
      </div>
      <div className={styles["header-item-container"]}>
        <div className={styles["header-icon-container"]}>
          <IconSystemTemperature />
        </div>
        <div className={styles["header-item-content"]}>
          <div className={styles["header-item-content-value"]}>
            <H3>{state.temperature}°C</H3>
          </div>
          <Label className={styles["header-item-content-label"]}>
            {t("temperature")}
          </Label>
        </div>
      </div>
    </div>
  );
};
