import { TFunction } from "i18next";
import { ISystemDto } from "../../systems";
import { ISystemTasksValues } from "../types";

export const getSystemTasksNames = (system: ISystemDto, t: TFunction) => {
  if (system.activeTasks.length === 0) return t("empty");
  return system.activeTasks.map((task) => t(task.toLowerCase())).join(", ");
};

export const getIconColor = (system: ISystemDto) => {
  if (system.activeTasks.length === 0) {
    return "var(--color-red-70)";
  }
  if (system.activeTasks.length === 3) {
    return "var(--color-green-70)";
  }
  return "var(--color-yellow-70)";
};

export const getIconBackgroundColor = (system: ISystemDto) => {
  if (system.activeTasks.length === 0) {
    return "red";
  }
  if (system.activeTasks.length === 3) {
    return "green";
  }
  return "yellow";
};

export const getDefaultValues = (system: ISystemDto): ISystemTasksValues => {
  return {
    Maintenance: system.activeTasks.some(t => t === "Maintenance"),
    Orders: system.activeTasks.some(t => t === "Orders"),
    Deliveries: system.activeTasks.some(t => t === "Deliveries"),
  }
}