import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISystemsSliceState, ISystemsStateMessage } from './types';
import { ApiStatuses } from '../../app/types';
import { getState, updateHealth as _updateHealth, updateEStop as _updateEStop, getActivity } from './api';
import { ActivityTypes } from '../systems';
import { MessageOperation } from '../signalR';

export const initialState: ISystemsSliceState = {
  status: ApiStatuses.initial,
  healthStatus: ApiStatuses.success,
};

export const fetchState = createAsyncThunk(
  'systemsAttributes/fetchState',
  async (systemId: number) => {
    const response = await getState(systemId);
    return response.data;
  }
);

export const updateHealth = createAsyncThunk(
  'systemsAttributes/updateHealth',
  async ({ systemId, status }: { systemId: number, status: boolean }) => {
    const response = await _updateHealth(systemId, status);
    return response.data;
  }
);

export const fetchActivity = createAsyncThunk(
  'systemsAttributes/fetchActivity',
  async ({ tenantId, startTime, endTime, types }: { tenantId: number, startTime: Date, endTime: Date, types: ActivityTypes[] }) => {
    const response = await getActivity(tenantId, startTime, endTime, types);
    return response.data;
  }
);

export const updateEStop = createAsyncThunk(
  'systems/updateEStop',
  async ({ systemId, status}: { systemId: number, status: boolean }) => {
    const response = await _updateEStop(systemId, status);
    return response.data;
  }
);

const slice = createSlice({
  name: "systemsAttributes",
  initialState,
  reducers: {
    updateStateFromSignalR(state: ISystemsSliceState, action: PayloadAction<{ operation: MessageOperation, stateString: string }>) {
      const updatedState = JSON.parse(action.payload.stateString) as ISystemsStateMessage;
      if (action.payload.operation === MessageOperation.Updates && state.systemId === updatedState.systemId) {
        state.state = updatedState.state;
      }
    },
    setSystemId(state: ISystemsSliceState, action: PayloadAction<number>) {
      state.systemId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchActivity
      .addCase(fetchActivity.fulfilled, (state, action) => {
        if (JSON.stringify(action.payload) === JSON.stringify(state.activity)) return;
        state.activity = action.payload;
      })
      // fetchState
      .addCase(fetchState.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.state = action.payload;
      })
      .addCase(fetchState.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      .addCase(updateHealth.fulfilled, (state) => {
        if (state.state) {
          state.state.health = !state.state.health;
        }
      })
      .addCase(updateHealth.rejected, (state) => {
        state.healthStatus = ApiStatuses.fail;
      })
      // updateEStop
      .addCase(updateEStop.fulfilled, (state) => {
        if (state.state) {
          state.state.eStop = !state.state.eStop;
        }
      })
  },
});

export const systemsAttributes = slice.reducer;
export const actions = slice.actions;