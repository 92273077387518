import { ApiStatuses, IApiError, Nullable } from "../../app/types";

export interface ISystemsSliceState {
  status: ApiStatuses,
  healthStatus: ApiStatuses;
  state?: ISystemsState;
  error?: IApiError;
  activity?: ISystemActivities;
  systemId?: number;
}

interface IStateBuffer {
  total: number;
  occupied: number;
}

export interface IUPSState {
  modelName: string;
  serialNumber: string;
  ratinVoltage: string;
  ratoutVoltage: string;
  batteryStatus: string;
  temperature: string;
  outSource: string;
}

export interface ISystemsStateMessage {
  systemId: number;
  state: ISystemsState;
}

export interface ISystemsState {
  buffer: Nullable<IStateBuffer>;
  eStop: Nullable<boolean>;
  health: boolean;
  systemMode: string;
  compressor: Nullable<boolean>;
  open: Nullable<boolean>;
  packerSupplyPercentage: Nullable<number>;
  temperature: Nullable<number>;
  timestamp: string;
  upsState: Nullable<IUPSState>;
}

export interface ISystemActivity {
  failed: number;
  startTime: string;
  endTime: string;
  total: number;
  succeeded: number;
}
export interface ISystemActivities {
  orderActivities: ISystemActivity[];
  reservationActivities: ISystemActivity[];
  validationActivities: ISystemActivity[];
}
export interface ISystemActivitiesState {
  orderActivities: boolean;
  reservationActivities: boolean;
  validationActivities: boolean;
}

export enum ITaskType {
  Orders = "Orders",
  Deliveries = "Deliveries",
  Maintenance = "Maintenance"
}

export enum ITimeFactor {
  Past = "Past",
  Present = "Present",
  Future = "Future"
}

export interface ITimelineTask {
  id: number;
  taskType: ITaskType;
  expectedTime: string;
  endTime: string;
  timeFactor: ITimeFactor;
}

export interface ISystemTasksValues {
  [ITaskType.Maintenance]: boolean;
  [ITaskType.Orders]: boolean;
  [ITaskType.Deliveries]: boolean;
}

export type ActivitiesDatesRanges = "today" | "day" | "week" | "month" | "year" | "quarter" | "custom";