import { ApiStatuses, IDateRange, IPagination, Nullable, SortTypes } from "../../app/types";

export enum EOrderStatus {
  DeliveryRequested = "DeliveryRequested",
  Pending = "Pending",
  Queued = "Queued",
  Approved = "Approved",
  InProgress = "InProgress",
  Packed = "Packed",
  PackedManually = "PackedManually",
  Delivering = "Delivering",
  CollectedManually = "CollectedManually",
  Collected = "Collected",
  Canceled = "Canceled",
  Failed = "Failed",
  PackingFailed = "PackingFailed",
  DeliveryFailed = "DeliveryFailed",
  ProcessingFailed = "ProcessingFailed",
  Shipped = "Shipped",
  PartialPacked = "PartialPacked",
  Archived = "Archived",
  ArchiveRequested = "ArchiveRequested",
}

export enum EOrderGroups {
  Pending = "Pending",
  InProgress = "InProgress",
  Packed = "Packed",
  Queued = "Queued",
  Collected = "Collected",
  Failed = "Failed",
  Canceled = "Canceled",
}

export enum EOrderOperations {
  Cancel = "Cancel",
  Queue = "Queue",
}

export enum ShippingStage {
  Pending = "Pending",
  Approved = "Approved",
  InProgress = "InProgress",
  Completed = "Completed",
  Canceled = "Canceled",
  Failed = "Failed",
}

export interface IOrderProduct {
  id: number;
  quantity: number;
  sku: string;
  name: string;
  manualCollectedQuantity: Nullable<number>;
  autoCollectedQuantity: Nullable<number>;
}

export interface IOrderPack {
  orderPartId: string;
  productId: number;
  createdOn: string;
  updatedOn: string;
}

export interface IStatusModel {
  createdOn: string;
  eventTime: string;
  id: number;
  orderId: string;
  status: EOrderStatus;
}

export interface IOrderShipping {
  address: string;
  createdOn: string;
  details: Nullable<string>;
  id: number;
  name: string;
  orderId: string;
  parcels: number;
  phoneNumber: string;
  shippingExternalCode: string;
  status: EOrderStatus;
  updatedOn: string;
  verificationCode: string;
}

export interface IShippingDetails {
  address: string;
  externalId: string;
  id: number;
  name: string;
  phoneNumber: string;
  status: EOrderStatus;
  statusTimeline: IStatusModel[];
  verificationCode: string;
  notes: string;
}

export interface IShippingDetailsEvent extends IShippingDetails {
  orderId: string;
}

export interface IManualPickupLocation {
  sku: string;
  palletId: string;
  side: string;
  row: number;
  column: number;
  quantity: number;
}

export interface IOrderPartProduct {
  packId: string;
  systemProductId: number;
  systemId: number;
  orderPartId: string;
  name: string;
  sku: string;
  count: number;
  itemsMetadata: OrderPartProductMetadata[];
}

export interface IOrderPartHistoryStatus {
  id: number;
  orderPartId: number;
  status: EOrderStatus;
  eventTime: string;
}

export interface IOrderPart {
  id: string;
  orderId: string;
  systemId: number;
  status: EOrderStatus;
  products: IOrderPartProduct[];
  packs: IOrderPack[];
  statuses: IOrderPartHistoryStatus[];
  createdOn: string;
  updatedOn: string;
}

export interface IOrder {
  code: string;
  createdOn: string;
  updatedOn: string;
  id: string;
  packs: Nullable<IOrderPack[]>;
  parts: Nullable<IOrderPart[]>;
  products: IOrderProduct[];
  shippingAttempts: number;
  status: EOrderStatus;
  statusTimeline: IStatusModel[];
  tenantId: number;
  shipping: Nullable<IShippingDetails>;
  shippings: Nullable<IOrderShipping[]>;
}

export interface IOrdersListFilters extends IPagination {
  idFilter: string;
  statusFilter: EOrderStatus[];
  dateFilter: IDateRange;
  dateFilterSort: SortTypes;
  viewType: Nullable<EOrdersViewType>;
}

export interface IOrdersSliceState {
  list: IOrder[];
  board: IOrder[];
  filters: IOrdersListFilters;
  selectedOrder?: IOrder;
  status: ApiStatuses;
  detailsStatus: ApiStatuses;
  selectedOrderId?: number;
  orderNotFound: boolean;
}

export interface IShippingRetry {
  address: string;
  name: string;
  phoneNumber: string;
  notes: string;
}

export enum EOrderPartOperations {
  RetryOrder = "RetryOrder",
  ManualPickUp = "ManualPickUp",
  ManualDelivery = "ManualDelivery",
}

export enum EShipmentOperations {
  RetryShipping = "RetryShipping",
}

export enum EOrdersViewType {
  table = "table",
  board = "board",
}

export interface IFetchListProps {
  tenantId: number;
  filters: IOrdersListFilters
}

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export type OrderPartProductMetadata = PartialRecord<string, string>;

export type IOrderPartProductWithMetadata = IOrderPartProduct & OrderPartProductMetadata;
