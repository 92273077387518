import { FC } from "react";
import {
  SystemHealth,
  SystemTasks,
  MachineSpeed,
} from "../../modules/systemAttributes";
import { ISystemDto } from "../../modules/systems";
import styles from "./Home.module.css";
import { SystemActivityGraph } from "../../modules/systemAttributes/SystemActivityGraph";
import { AcceptNewOrders } from "../../modules/tenants";

interface Props {
  system: ISystemDto;
}

export const Home: FC<Props> = ({ system }) => {
  return (
    <div>
      <div className={styles["grid-container"]}>
        <div className={styles["grid-item"]}>
          <SystemHealth systemId={system.id} />
        </div>
        <div className={styles["grid-item"]}>
          <AcceptNewOrders system={system} />
        </div>
        <div className={styles["grid-item"]}>
          <SystemTasks system={system} />
        </div>
        <div className={styles["grid-item"]}>
          <MachineSpeed system={system} />
        </div>
        {/* TODO: API implementation needed
          <SystemMode systemId={systemId} state={state} />
         */}
      </div>
      <div className={styles.graph}>
        <SystemActivityGraph tenantId={system.tenantId} />
      </div>
    </div>
  );
};
