import { AxiosPromise } from 'axios';
import { axios } from '../axios';
import { ISystemDto } from './types';

export const getSystems = (): AxiosPromise<ISystemDto[]> => {
  return axios.get('/systems');
};

export const getSystem = (systemId: number): AxiosPromise<ISystemDto> => {
  return axios.get(`/systems/${systemId}`);
};

export const updateSystem = (systemId: number, system: Partial<ISystemDto>): AxiosPromise<ISystemDto> => {
  return axios.patch(`/systems/${systemId}`, system);
};